import styled from "styled-components";

export const ContainerDiv = styled.div`
  @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400&display=swap");
`;
export const HeadingDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  border: 0 solid;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  box-shadow: 0 0 2px 0 ${({ theme }) => theme.colors.grayL1 || "#323232"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  display: inherit;
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
  width: 100%;
  font-style: normal;
  font-weight: 300;
  padding-left: 10px;
  float: left;
  text-align: left;
  line-height: ${({ theme }) => theme.lineHeights.line2};
  font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
`;
export const ContainerData = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL6 || "#F2F2F2"};
  margin-bottom: 20px;
  clear: both;
`;
export const NavContainer = styled.div``;

export const LeftArrowIcon = styled.div``;
export const HeadingLeft = styled.div`
  float: left;
  font-size: ${({ theme }) => theme.fontSizes.font6} !important;
`;
export const HeadingViewRight = styled.div`
  float: right;
  a {
    color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    font-family: ${({ theme }) => theme.fontFamily.secondaryFont};
    font-size: ${({ theme }) => theme.fontSizes.font8} !important;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-right: 10px;
    .icon {
      font-size: ${({ theme }) => theme.fontSizes.font8} !important;
      display: contents;
    }
  }
`;
